@font-face{
    font-family: abhaya-libre;
    src: url('../fonts/Abhaya-Libre/AbhayaLibre-Regular.ttf');
    font-display: swap;  
}

@font-face{
    font-family: abhaya-libre;
    src: url('../fonts/Abhaya-Libre/AbhayaLibre-Medium.ttf');
    font-weight: 600;
    font-display: swap;  
}

@font-face{
    font-family: abhaya-libre;
    src: url('../fonts/Abhaya-Libre/AbhayaLibre-Bold.ttf');
    font-weight: 700;
    font-display: swap;  
}

@font-face{
    font-family: abhaya-libre;
    src: url('../fonts/Abhaya-Libre/AbhayaLibre-SemiBold.ttf');
    font-weight: 800;
    font-display: swap;
}

@font-face{
    font-family: abhaya-libre;
    src: url('../fonts/Abhaya-Libre/AbhayaLibre-ExtraBold.ttf');
    font-weight: 900;
    font-display: swap;
}



@font-face {
    font-family: helvetica;
    src: url('../fonts/Helvetica/Helvetica.ttf');
    font-display: swap;
}


@font-face {
    font-family: helvetica;
    src: url('../fonts/Helvetica/Helvetica-Bold.ttf');
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: helvetica;
    src: url('../fonts/Helvetica/helvetica-light.ttf');
    font-weight: light;
    font-display: swap;
}


@font-face {
    font-family: barlow-condensed;
    src: url('../fonts/Barlow-Condensed/BarlowCondensed-Regular.ttf');
    font-display: swap;
}

@font-face{
    font-family: barlow-condensed;
    src: url('../fonts/Barlow-Condensed/BarlowCondensed-Medium.ttf');
    font-weight: 500;
    font-display: swap;  
}

@font-face{
    font-family: barlow-condensed;
    src: url('../fonts/Barlow-Condensed/BarlowCondensed-SemiBold.ttf');
    font-weight: 600;
    font-display: swap;  
}

@font-face{
    font-family: barlow-condensed;
    src: url('../fonts/Barlow-Condensed/BarlowCondensed-Bold.ttf');
    font-weight: 700;
    font-display: swap;
}


@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-Regular.ttf');
    font-weight: 500;
    font-display: swap;
}
@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-Regular.ttf');
    font-weight: 600;
    font-display: swap;
}
@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-Bold.ttf');
    font-weight: 700;
    font-display: swap;
}
@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-ExtraBold.ttf');
    font-weight: 800;
    font-display: swap;
}
@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-Light.ttf');
    font-weight: 400;
    font-display: swap;
}



:root{
    --primary-ss-color:#EF4160;
    --secondary-ss-color: #F01B42;
    --primary-ssl-color: #FF7991;
    --secondary-ssl-color: #C13650;

    --heading-color: #373737;
    --para-color: #373737;
    --grey-color: #373737;
    --white-color: #ffffff;
    --black-color: #000000;

    --primary-ss-font-family: abhaya-libre, sans-serif;
    --secondary-ss-font-family: helvetica, sans-serif;
    --custom-ss-font-family: barlow-condensed, sans-serif;
    --poppin-ss-font-family: poppins, sans-serif;
}

*{
    padding: 0;
    margin: 0;
}

ol, ul{
    margin-top: 0px;
    margin-bottom: 0px;
}

body{
    font-family: helvetica, sans-serif;
}

.heading-font{
    font-family: abhaya-libre, sans-serif;
}
.para-font{
    font-family: helvetica, sans-serif;
}

.text-abhaya-libre{
    font-family: abhaya-libre, sans-serif;
}
.text-helvetica{
    font-family: helvetica, sans-serif;
}
.text-barlow{
    font-family: barlow-condensed, sans-serif;
}
.text-poppins{
    font-family: poppins, sans-serif;
}

/* .header-logo{
    color: #00477f;
} */

/* .header-logo:hover{
    color: var(--primary-zk-color)
} */

.text-ss-primary{
    color: var(--primary-ss-color);
}
.text-ss-secondary{
    color: var(--secondary-ss-color);
}

.heading-color{
    color: var(--heading-color);
}
.para-color{
    color: var(--para-color);
}

.text-black{
    color: var(--black-color);
}
.text-white{
    color: var(--white-color);
}




.bg-ss-primary{
    background-color: var(--primary-ss-color);
}
.bg-ss-secondary{
    background-color: var(--secondary-ss-color);
}
.bg-ss-dark{
    background-color: var(--grey-color);
}
.bg-ss-light{
    background-color: var(--white-color);
}

.bg-ss-sec{
    background-color: transparent; /* For browsers that do not support gradients */
    /* background-image: linear-gradient(90deg, #D3E0E160 0%, #D6F2EB60 100%); */
    background-image: linear-gradient(90deg, rgba(211, 224, 225, 0.5) 0%, rgba(214, 242, 235, 0.5) 100%);
}


.btn-ss-secondary-header{
    background-color: var(--secondary-ss-color);
    font-size: 16px;
    font-family: var(--primary-ss-font-family);
    font-weight: 400;
    text-transform: capitalize;
    color: #FFFFFF;
    padding: 8px 22px;
    border: none;
    border-radius: 80px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;    
}
.btn-ss-secondary-header:hover{
    background-color: var(--secondary-ssl-color);
}


.btn-ss-primary{
    background-color: var(--primary-ss-color) !important;
    font-size: 18px;
    font-family: var(--secondary-ss-font-family);
    font-weight: 500;
    text-transform: capitalize;
    color: #FFFFFF !important;
    padding: 6px 30px;
    border: none;
    border-radius: 80px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    min-width: 160px; 
}
.btn-ss-primary:hover{
    background-color: var(--secondary-ssl-color) !important;
    color: #FFFFFF !important;
}
.btn-ss-primary:focus{
    background-color: var(--secondary-ssl-color);
    color: #FFFFFF;
    cursor: pointer;
}
.btn-ss-primary:active{
    background-color: var(--secondary-ssl-color);
    color: #FFFFFF;
}


.btn-ss-secondary{
    background-color: transparent;
    font-size: 20px;
    font-family: var(--secondary-ss-font);
    font-weight: 600;
    line-height: 1.2em;
    /* text-transform: capitalize; */
    color: #373737;
    padding: 10px 32px;
    border: solid 2px;
    border-color: #373737;
    border-radius: 50px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: transform .4s;    
}
.btn-ss-secondary:hover{
    background-color: transparent;
    border: solid 2px;
    /* zoom: 1.1; */
    /* transition: 0.3s; */
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1); 
}
.btn-ss-secondary:focus{
    background-color: transparent;
    border: solid 2px;
    cursor: pointer;
}
.btn-ss-secondary:active{
    background-color: transparent;
    border: solid 2px;
}

.btn-width-250{
    min-width: 250px;
    padding-right: 80px;
    padding-left: 80px;
}


.btn-form{
    color: white;
    background-color: var(--secondary-ss-color);
    padding: 5px;
    border-radius: 80px;
    font-size: 18px;
    font-weight: 500;
    font-family: var(--primary-ss-font-family);
    text-transform: capitalize;
    border: none;
}

.border-ss-primary{
    border-top: 3px solid var(--primary-zk-color);
    border: none;
    height: 3px;
    opacity: 1;
    color: var(--primary-zk-color);
    background-color: var(--primary-zk-color);

}
.border-ss-white{
    border-top: 2px solid #fff;
    border: none;
    height: 2px;
    opacity: 1;
    color: #fff;
    background-color: #fff;

}




.fs-ss-main{
    font-size: 96px;
}
.fs-ss-60{
    font-size: 60px;
}
.fs-ss-56{
    font-size:56px;
}
.fs-ss-52{
    font-size:52px;
}
.fs-ss-46{
    font-size: 46px;
}
.fs-ss-42{
    font-size: 42px;
}
.fs-ss-40{
    font-size: 40px;
}
.fs-ss-36{
    font-size: 36px;
}
.fs-ss-32{
    font-size: 32px;
}
.fs-ss-30{
    font-size: 30px;
}
.fs-ss-28{
    font-size: 28px;
}
.fs-ss-26{
    font-size: 26px;
}
.fs-ss-24{
    font-size: 24px;
}
.fs-ss-22{
    font-size: 22px;
}
.fs-ss-20{
    font-size: 20px;
}
.fs-ss-18{
    font-size: 18px;
}
.fs-ss-17{
    font-size: 17px;
}
.fs-ss-16{
    font-size: 16px;
}
.fs-ss-15{
    font-size: 15px;
}
.fs-ss-14{
    font-size: 14px;
}
.fs-ss-13{
    font-size: 13px;
}
.fs-ss-12{
    font-size: 12px;
}


.fw-900{
    font-weight: 900;
}
.fw-800{
    font-weight: 800;
}
.fw-700{
    font-weight: 700;
}
.fw-600{
    font-weight: 600;
}
.fw-500{
    font-weight: 500;
}
.fw-400{
    font-weight: 400;
}


.lh-1-1{
    line-height: 1.1;
}
.lh-1-2{
    line-height: 1.2;
}
.lh-1-3{
    line-height: 1.3;
}
.lh-1-4{
    line-height: 1.4;
}
.lh-1-5{
    line-height: 1.5;
}
.lh-1-6{
    line-height: 1.6;
}
.lh-1-7{
    line-height: 1.7;
}
.lh-1-8{
    line-height: 1.8;
}


.ls-0-5{
    letter-spacing: 0.5px;
}
.ls-1{
    letter-spacing: 1px;
}
.ls-1-2{
    letter-spacing: 1.2px;
}
.ls-1-5{
    letter-spacing: 1.5px;
}
.ls-1-8{
    letter-spacing: 1.8px;
}
.ls-2{
    letter-spacing: 2px;
}


.w-95{
    width: 95%;
}
.w-90{
    width: 90%;
}
.w-88{
    width: 88%;
}
.w-85{
    width: 85%;
}
.w-80{
    width: 80%;
}
.w-40{
    width: 40%;
}
.w-30{
    width: 30%;
}
.w-20{
    width: 20%;
}


.border-top-ss{
    border-style: solid;
    border-width: 1px 0px 0px 0px;
    border-color: #37373740;
}


.logo-img{
    height:40px;
}

.container-ss{
    max-width: 98%;
    padding-right: 2%;
    padding-left: 2%;
    margin-right: auto;
    margin-left: auto;
}
.container-ss-90{
    max-width: 90%;
    padding-right: 4%;
    padding-left: 4%;
    margin-right: auto;
    margin-left: auto;
}
.container-ss-85{
    max-width: 85%;
    padding-right: 4%;
    padding-left: 4%;
    margin-right: auto;
    margin-left: auto;
}
.container-ss-80{
    max-width: 80%;
    padding-right: 8%;
    padding-left: 8%;
    margin-right: auto;
    margin-left: auto;
}

.container-ss-700{
    max-width: 700px;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
}


/* Heading Style */

.heading{
    font-family: var(--secondary-ss-font-family);
    font-size: 36px;
    line-height: 1.4em;
    font-weight: 800;
}

.heading-32{
    font-family: var(--secondary-ss-font-family);
    font-size: 32px;
    line-height: 1.4em;
    font-weight: 800;
}

h1{
    text-transform: uppercase;
}
h2{
    font-family: var(--primary-ss-font-family);
    font-size: 56px;
    line-height: 1.1em;
    letter-spacing: 1px;
    /* font-weight: 800; */
    text-transform: uppercase;
    font-weight: 700;
}
h3{
    font-family: var(--primary-ss-font-family);
    font-size: 32px;
    line-height: 1.4em;
    letter-spacing: 1px;
    font-weight: 700;
}
h4{
    font-family: var(--primary-ss-font-family);
    font-size: 28px;
    line-height: 1.2em;
    font-weight: 700;
}

.sub-heading{
    font-family: var(--poppins-ss-font-family);
    color: var(--primary-ss-color);
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4em;
    letter-spacing: 0.5px;
}
.sub-heading-v1{
    font-family: var(--poppins-ss-font-family);
    color: var(--primary-ss-color);
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4em;
    letter-spacing: 0.5px;
}

.service-heading{
    font-family: var(--custom-ss-font-family);
    font-size: 32px;
    line-height: 1.3em;
    font-weight: 500;
}

/* ---------- ---------- */

/* Paragraph Style */

p{
    font-family: var(--secondary-ss-font-family);
    font-size: 16px;
    line-height: 1.6em;
    /* font-weight: light; */
    margin-bottom: 1.6em;
}

.para{
    font-family: var(--primary-ss-font-family);
    font-size: 16px;
    font-weight: 500;
}

.para1{
    font-family: var(--primary-ss-font-family);
    font-size: 18px;
    line-height: 1.7em;
    font-weight: 400;
}

.text-justify{
    text-align: justify;
}

/* ---------- ---------- */

.main-sec{
    padding: 100px 20px 80px 20px;
}

.fixed-top-ss{
    position: fixed;
    right: 0px;
    left: 0px;
    margin-top: 80px;
    z-index: 999;
    opacity: 1;
}

.service-icon-size{
    width: 40%;
}

.rounded-ss{
    border-radius: 30px;
}
.rounded-ss-50{
    border-radius: 50px;
}

.text-left-desk{
    text-align: left;
}
.text-justify-desk{
    text-align: justify;
}

.card-deck {
    display: inline-flex;
    flex-wrap: nowrap;
}







.services-list{
    font-family: var(--secondary-ss-font-family);
    font-size: 18px;
    /* line-height: 1.6em; */
    font-weight: 800;
    color: var(--black-color);
    text-decoration: none;
}
.services-list-active{
    font-family: var(--secondary-ss-font-family);
    font-size: 24px;
    /* line-height: 1.6em; */
    font-weight: 800;
    color: var(--secondary-ss-color);
    text-decoration: none;
}

#website-nav .navbar-toggler:focus{
    outline: none;
    box-shadow: none;
}

#website-nav .navbar-toggler{
    border: none;
    border-radius: 0;
    background-color: #fff;
}

#website-nav .nav-link{
    color: var(--primary-ss-color);
    margin-right: 10px;
    padding-top: 0;
    padding-bottom: 0;
}
#website-nav .nav-link:hover {
    color: var(--primary-ss-color) !important;
}

#website-nav .sub-nav-menu:hover .dropdown-menu {
    display: block;
}

#website-nav .sub-nav-menu:hover .sub-sub-child-menu .dropdown-menu {
    display: none;
}

#website-nav .sub-nav-menu .dropdown-menu {
    margin-top: 0;
}
#website-nav .sub-child-menu:hover .dropdown-menu {
    display: block;
    visibility: visible;
    margin-top: -10px;
}

#website-nav .sub-child-menu .sub-sub-child-menu:hover .dropdown-menu{
    display: block;
    visibility: visible;
}

#website-nav .active{
    color: var(--secondary-ss-color);
}

#website-nav .dropdown-toggle{
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
}

.dropdown-item.active, .dropdown-item:active{
    background-color: var(--secondary-ss-color);
}

#website-nav .sub-child-menu .dropdown-menu {
    /* display: none; */
    visibility: hidden;
    margin-top: 0;
}
#website-nav .sub-child-menu .dropdown-menu a{
    padding: 5px 10px;
    font-size: 15px;

}
#website-nav .sub-child-menu .dropdown-menu{
    width: 240px;
}
#website-nav .dropdown-item{
    cursor: pointer;
}
.navbar-offcanvas{
    /* background-color: var(--primary-zk-color);
    color: #fff; */
    padding: 0rem;
}

.take-control-bg{
    background-color: var(--primary-zkl-color) ;
    /* background color to -10vw */
    background-position: 0 -10vw;
}

.minusBg10 {
    margin-top: -100px;
}

.minusBg12 {
    margin-top: -120px;
}


/* .lb{
    margin-top: 30px;
    margin-left: 5px;
    padding-left: 30px;
    border-left: 4px solid #004bda;
} */


/* .slick-slide-title {
    color: var(--primary-ss-color);
    padding: 10px;
    position: absolute; */
    /* text-transform: ; */
    /* font-size: 60px; */
    /* font-size: 3.5vw;
    font-family: Raleway;
    font-weight: 800;
    left: 8vw;
    top: 25%;
    width: 60%;
    line-height: 70px;
} */

.slick-slide-title {
    color: var(--primary-ss-color);
    padding: 10px;
    position: absolute;
    font-size: 60px;
    font-family: Raleway;
    font-weight: 800;
    left: 8vw;
    top: 22%;
    width: 60%;
}

.slick-slide-title-desc {
    color: var(--primary-ss-color);
    padding: 10px;
    position: absolute;
    left: 8vw;
    font-size: 3.5vw;
    font-weight: 500;
    top: 28%;
    width: 60%;
}
.slick-slide-btn{
    position: absolute;
    /* position: relative; */
    left: 9vw;
    font-weight: 500;
    top: 67%;
    width: 60%;
}


.slick-slide-title-div {
    color: var(--primary-ss-color);
    padding: 10px;
    position: absolute;
    font-size: 60px;
    font-family: Raleway;
    font-weight: 800;
    left: 8vw;
    top: 25%;
    width: 60%;
    line-height: 70px;
}
.slick-slide-btn2{
    font-weight: 500;
    margin-top: 20px;
    margin-left: 5px;
}

.title-span{
    font-family: "Raleway", Sans-serif;
    font-size: 36px;
    font-weight: 800;
    text-transform: capitalize;
}
  


.bg-contact-opacity{
    background-color: #181818;
    opacity: 0.95;
}

.banner-section{
    position: relative;
}
/* .about-col-bg-white{
    background-color: #ffffff;
    border-radius: 10px;
    align-content: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
}
.about-col-b{
    border: 3px solid #004bda;
    border-radius: 10px;
    align-content: center;
} */

.w80px {
    width: 80px;
}

.border-header{
    border-bottom: 4px solid var(--secondary-zk-color) !important;
}

.border-zk-primary2 {
    background-color: var(--primary-zk-color);
    border: none;
    color: var(--primary-zk-color);
    height: 2px;
    opacity: 1;
}
.accordion-button{
    padding: 10px 20px !important;
}
.accordion-button:focus{
    box-shadow: 0;
}
.accordion-button:not(.collapsed){
    background-color: #ededed !important;
}
.accordion-button:focus{
    box-shadow: none !important;
}
.accordion-item {
    border-left: 0 !important;
    border-radius: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
}

.accordion{
    border: 0;
}

.splash-screen {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f2f3f8;
    color: #5E6278;
    line-height: 1;
    font-size: 14px;
    font-weight: 400;
}
  
.splash-screen span {
color: #5E6278;
transition: none !important;
text-size-adjust: 100%;
-webkit-font-smoothing: antialiased;
}

.splash-screen img {
margin-left: calc(100vw - 100%);
margin-bottom: 30px;
height: 38px !important;
}

.slick-slide-image {
    max-width: 100%;
    height: auto;
    /* background-size: contain; */
    /* background-size: cover; */
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
      0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
}
.slick-slide-label {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 0px;
    font-size: 1.5em;
    bottom: 0px;
    width: 100%;
}
.slick-prev:before,
.slick-next:before {
    color: #01054e !important;
}

.slick-slide-content{
    color: #fff;
    position: absolute;
    left: 7vw;
    top: 30%;
    width: 45%;
}


.slider-wrapper {
    width: 100%;
    margin: auto;
  }

  /* ---------- Main Slider ---------- */

  .btn-zk-white{
    background-color: #fff;
    color: black;
    border: 0;
    border-radius: 0;
    padding: 10px 20px;
    /* font-size: 1.1vw; */
    font-weight: 500;
  }

.btn-zk-white:hover{
    background-color: rgb(238, 238, 238);
    color: black;
    border: 0;
    padding: 10px 20px;
    /* font-size: 1.1vw; */
    font-weight: 500;
}
.btn-zk-white:focus{
    background-color: rgb(238, 238, 238) !important;
    color: black !important;
}
.home-banner .row{
    margin: 0;
}

/* .footer-bg{
    background: url('../images/footer.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
} */

.footer-links-section{
    padding-top: 200px;
    padding-bottom: 40px;
}
.border-zk-secondary-footer{
    background-color: var(--secondary-zk-color);
    border: none;
    color: var(--secondary-zk-color);
    height: 3px;
    opacity: 1;
    width: 100px;
}
.border-gray2{
    border-top: 1px solid #ffffff40
}

  .rounded30{
    border-radius: 30px;
  }

  .rounded20{
    border-radius: 20px;
  }

  .rounded10{
    border-radius: 10px;
  }

  .rounded6{
    border-radius: 6px;
  }

  .btn-zk-outline-white{
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    /* height: 3em; */
    min-width: 120px;
    font-family: work-sans;
    font-weight: 600 !important;
    border: 1px solid #fff;

}
.btn-zk-primary{
    background-color: var(--primary-zk-color);
    color: #fff;
    cursor: pointer;
}

.btn-zk-secondary{
    background-color: var(--secondary-zk-color);
    color: #fff;
    cursor: pointer;
}


.btn-zk-primary-banner{
    background-color: var(--primary-zk-color);
    color: #fff;
    cursor: pointer;
    /* padding: 2vw 5vw; */
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Work Sans', sans-serif;
    text-transform: capitalize !important;
    letter-spacing: 0.5px;
}

.btn-zk-primary-banner:hover{
    background-color: var(--primary-zk-color);
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
    padding: 1vw 2vw;
}

.btn-zk-white-banner{
    background-color: #fff;
    color: var(--primary-zk-color);
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Work Sans', sans-serif;
    text-transform: capitalize !important;
    letter-spacing: 0.5px;
    border-radius: 30px;
}

.btn-zk-white-banner:hover{
    background-color: #fdfdfd;
    opacity: 0.94;
    color: var(--primary-zk-color);
    cursor: pointer;
    padding: 1vw 2vw;
}
.home-background-banner{
    /* background-image: url('../images/home/banner.png'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 82vh;
    width: 100%;
    position: relative;
}
.home-banner-content{
    position: absolute;
    top: 15%;
    width: 55%;
    left: 13%;
}
.home-banner-heading{
    letter-spacing: 3px;
}
.home-banner-btn{
    font-size: 18px;
    border-radius: 30px !important;
}
.btn-zk-primary:hover{
    background-color: var(--primary-zk-color);
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
}

.btn-zk-secondary:hover{
    background-color: var(--secondary-zk-color);
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
}

.btn-zk-secondary:focus{
    background-color: var(--secondary-zk-color);
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
}

.shadow-ss{
    box-shadow: 2px 3px 3px 2px #dadada !important;
}

.shadow-ss-1{
    box-shadow: 1px 3px 10px 1px #00000020 !important;
}

.padding10xem{
    padding: 10em 0;
}

.padding7xem{
    padding: 7em 0;
}

.padding5xem{
    padding: 5em 0;
}

.padding2-2xem{
    padding: 2.2em 0;
}
.padding3xem{
    padding: 3em 0;
}


.navbar-nav .dropdown-menu {
    background: #fdfdfd;
    border: rgb(255, 255, 255);
    box-shadow: #dadada 0px 0px 2px 0px;
    border-radius: 0;
    border-top: 2px solid var(--primary-ss-color);
    position: static;
    width: 100%;
}

#website-nav .sub-nav-menu:hover .dropdown-menu {
    display: block;
}
#website-nav .sub-nav-menu .dropdown-menu {
    width: max-content;
    margin-top: 0;
}

.emr_featured_col{
    /* middle */
    min-height: 55px;

}

.card-stretch{
    height: calc(100% - 1px);
}

/* .service-healthcare-bg{
    background-image: url('../images/Services/healthcare-bg.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
} */

.home-mobile-pic{
    width: 30%;
}
.page-banner-title{
    color: var(--primary-ss-color);
    font-family: var(--secondary-ss-font-family);
    position: absolute;
    font-weight: 800;
    /* text-transform: uppercase; */
    left: 8vw;
    font-size: 60px;
    line-height: 4.3vw;
    top: 35%;
    letter-spacing: 1px;
    /* width: 80%; */
  }
  .page-banner-text1{
    font-size: 2vw;
  }
  .page-banner-desc{
    font-size: 1.4vw;
    font-weight: 400;
    margin-top: 10px;
    /* margin-top: 1px; */


 

  }

.navbar-nav .dropdown-menu {
    box-shadow: 0;
    border-radius: 0;
    border: 0;
    padding: 10px;
}

.digital-marketing-icon{
    width: 50% !important;
}
.about-weuse-section{
    min-height: 175px !important;
}

.about-ourphilosophy{
    width: 130px;
    height: 120px;
}
.home-healthimages{
    width: 40%;
}
.home-practice-img{
    width: 100%;
}

.home-practice-animation{
    width: 50%;
}
.whoweserve-healthimages{
    width: 25%;
} 

.about-connection-sec{
    min-height: 460px;
}
.marginTopvitals1{
    margin-top: -180px;
}

.paddingTopvitals2{
    padding-top: 190px;
}

.p-top{
    padding-top: 100px;
}

.p-bottom{
    padding-bottom: 100px;
}

.section1{
    background-position: center left;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 500px;
}

.section2{
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 500px;
}

.bg-os{
    background-color: #f2f5f7;
}


/* .bg-home-middle{
    background-image: url(../../assets/images/home/middle-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 320px;
} */

/* .bg-about-middle{
    min-height: 320px;
    background-color: var(--primary-ssl-color);
    background: linear-gradient(to top, var(--primary-ssl-color) 78%, white 22%);
} */

/* .bg-middle-banner{
    background-image: url(../../assets/images/middle-banner-v1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 320px;
} */

/* .middle-banner-services{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    min-height: 320px;
}
.bg-middle-banner-urgentcare{
    background-image: url(../../assets/images/urgent-care/middle-banner-v1.png);
}
.bg-middle-banner-physicalexam{
    background-image: url(../../assets/images/physical-exam/middle-banner-v1.png);
}
.bg-middle-banner-pediatricscare{
    background-image: url(../../assets/images/pediatrics-care/middle-banner-v1.png);
}
.bg-middle-banner-womenshealth{
    background-image: url(../../assets/images/womens-health/middle-banner-v1.png);
}
.bg-middle-banner-covid19testing{
    background-image: url(../../assets/images/covid19-testing/middle-banner-v1.png);
}
.bg-middle-banner-immunization{
    background-image: url(../../assets/images/immunization/middle-banner-v1.png);
}
.bg-middle-banner-preventativecare{
    background-image: url(../../assets/images/preventative-care/middle-banner-v1.png);
} */


.bg-testimonials{
    min-height: 320px;
    background-color: var(--primary-ssl-color);
    /* background-image: linear-gradient(to right, var(--primary-ssl-color) 70% , black 30%); */
    background: linear-gradient(to right, var(--primary-ssl-color) 70%, white 30%);
}



/* ---------- Our Services Section ---------- */
.container-services{
    max-width: 1020px;
    padding-right: 2% !important;
    padding-left: 2% !important;
    margin-right: auto;
    margin-left: auto;
}

.container-services1{
    max-width: 780px;
    padding-right: 2% !important;
    padding-left: 2% !important;
    margin-right: auto;
    margin-left: auto;
}

.services-div {
    position: absolute;
    left: 30px;
    bottom: 10px;
    /* width: 200px;
    height: 100px; */
}

.services-heading{
    color: var(--primary-ss-color);
    font-family: var(--secondary-ss-font-family);
    font-size: 22px;
    font-weight: 700;
}

.border-ss-secondary{
    background-color: var(--secondary-ss-color);
    border: none;
    color: var(--secondary-ss-color);
    height: 3px;
    opacity: 1;
    /* width: 100px; */
}

.bg-service{
    color: #FFFFFF;
    border-radius: 10px;
    height: 310px;
    width: 500px;
    vertical-align: middle;
    display: table-cell;
}

.bg-service:hover .services-div{
    position: static;
    align-items: center;
    color: #FFFFFF;
}
.bg-service:hover .services-heading{
    color: #FFFFFF;
    font-family: var(--primary-ss-font-family);
    font-size: 24px;
    font-weight: 600;
}
.bg-service:hover .border-ss-secondary{
    background-color: #FFFFFF;
    border: none;
    color: #FFFFFF;
    height: 3px;
    width: 100px;
}


/* .bg-service1{
    background: url('../images/services/Service1.png');
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
}
.bg-service1:hover{
    background: url('../images/services/Service1.1.png');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
}

.bg-service2{
    background: url('../images/services/Service2.png');
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
}
.bg-service2:hover{
    background: url('../images/services/Service2.1.png');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
}

.bg-service3{
    background: url('../images/services/Service3.png');
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
}
.bg-service3:hover{
    background: url('../images/services/Service3.1.png');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
}

.bg-service4{
    background: url('../images/services/Service4.png');
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
}
.bg-service4:hover{
    background: url('../images/services/Service4.1.png');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
}

.bg-service5{
    background: url('../images/services/Service5.png');
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
}
.bg-service5:hover{
    background: url('../images/services/Service5.1.png');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
}

.bg-service6{
    background: url('../images/services/Service6.png');
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
}
.bg-service6:hover{
    background: url('../images/services/Service6.1.png');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
}

.bg-service7{
    background: url('../images/services/Service7.png');
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
}
.bg-service7:hover{
    background: url('../images/services/Service7.1.png');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
}

.bg-service8{
    background: url('../images/services/Service8.png');
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
}
.bg-service8:hover{
    background: url('../images/services/Service8.1.png');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
}

.bg-service9{
    background: url('../images/services/Service9.png');
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
}
.bg-service9:hover{
    background: url('../images/services/Service9.1.png');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
} */

/* ---------- ---------- ---------- */


/* .bg-primarycare-section1{
    background-image: url(../../assets/images/PrimaryCare/PrimaryCareSection1.png);
} */

/* .bg-urgentcare-section1{
    background-image: url(../../assets/images/UrgentCare/UrgentCareSection1.png);
}
.bg-skincancer-section1{
    background-image: url(../../assets/images/SkinCancer/SkinCancerSection1.png);
}

.bg-cardiology-section1{
    background-image: url(../../assets/images/Cardiology/cardiologySection1.png);
}

.bg-vaccination-section1{
    background-image: url(../../assets/images/Vaccinations/vaccinationSection1.png);
}

.bg-pediatrics-section1{
    background-image: url(../../assets/images/PediatricCare/pediatricsSection1.png);
}

.bg-behavioralhealth-section1{
    background-image: url(../../assets/images/BehavioralHealth/behavioralHealthSection1.png);
}

.bg-internalmedicine-section1{
    background-image: url(../../assets/images/InternalMedicine/internalMedicineSection1.png);
}

.bg-primarycare-section2{
    background-image: url(../../assets/images/PrimaryCare/PrimaryCareSection2.png);
}

.bg-urgentcare-section2{
    background-image: url(../../assets/images/UrgentCare/UrgentCareSection2.png);
}
.bg-skincare-section2{
    background-image: url(../../assets/images/SkinCancer/SkinCancerSection2.png);
}

.bg-cardiology-section2{
    background-image: url(../../assets/images/Cardiology/cardiologySection2.png);
}

.bg-vaccination-section2{
    background-image: url(../../assets/images/Vaccinations/vaccinationSection2.png);
}

.bg-pediatrics-section2{
    background-image: url(../../assets/images/PediatricCare/pediatricsSection2.png);
}

.bg-behavioralhealth-section2{
    background-image: url(../../assets/images/BehavioralHealth/behavioralHealthSection2.png);
}

.bg-internalmedicine-section2{
    background-image: url(../../assets/images/InternalMedicine/internalMedicineSection2.png);
}

.bg-about{
    background-image: url(../../assets/images/AboutUs/BannerImg.png);
} */

.col-star{
    color: #fb8e29;
}


table tr td, table tr th{
    background-color: rgba(255, 255, 255, 0) !important;
}

.border-bottom{
    border-bottom: 1px solid #000000 !important;  
}

.shadow-os{
   box-shadow: -2px 3px 3px 1px rgb(0 0 0 / 15%);
}

.shadow-services1{
    box-shadow: 2px 2px 10px 2px rgb(0 0 0 / 10%);
}

.service-img-size{
    width: 55px;
}



.section1 .row{
    margin-right: 0;
    margin-left: 0;
}

.btn-zk-white-secondary{
    background-color: #fff;
    border: 3px solid var(--primary-zk-color);
}
.btn-zk-white-secondary:hover{
    background-color: var(--secondary-zk-color);
    color: #fff;
}

.ls-lg-zk-aim{
    letter-spacing: 16px;
}

.provider-img{
    width: 100%;
    border-radius: 6%;
    /* box-shadow: 2px 2px 3px 2px #cacaca !important; */
}

.provider-bg{
    background: linear-gradient(var(--primary-zk-color) 0 50%, white 50% 100%);
    border-radius: 6%;
    padding: 40px 10px 20px 10px;
    box-shadow: 2px 2px 3px 2px #cacaca !important;
    margin: 0;
}
.provider-pic-w{
    max-width: 65%;
}
.provider-sec-bg{
    background-color: #F2F5F7;
}

.provider-col-bg{
    background: white;
    border-radius: 0%;
    padding: 20px 10px 5px 10px;
    box-shadow: 1px 1px 2px 2px #dadada;
    margin: 0;
}


.image-width-90{
    width: 90%;
}
.image-width-85{
    width: 85%;
}
.image-width-80{
    width: 80%;
}
.image-width-75{
    width: 75%;
}

.insurance-logo img{
    width: 180px;
}

@media screen and (max-width: 1281px) {
    .home-marketing-section-content .fs-zk-16{
        font-size: 15px;
    }
    
}
@media screen and (max-width: 1201px){
    .container-ss-90{
        max-width: 98%;
        padding-right: 4%;
        padding-left: 4%;
        margin-right: auto;
        margin-left: auto;
    }
    .container-ss-85{
        max-width: 98%;
        padding-right: 4%;
        padding-left: 4%;
        margin-right: auto;
        margin-left: auto;
    }

}

@media screen and (max-width: 1024px){


}

@media screen and (max-width: 991px) {
    /* .container-ss-85{
        max-width: 98%;
        padding-right: 4%;
        padding-left: 4%;
        margin-right: auto;
        margin-left: auto;
    } */

    .main-sec{
        padding: 80px 20px 60px 20px;
    }
    #footer .w-75{
        width: 50% !important;
    }
    .service-icon-size{
        width: 70%;
    }
    .service-heading{
        font-size: 24px;
    }

    .text-uppercase-desk{
        text-transform: none !important;
    }
    .fs-ss-main{
        font-size: 66px;
    }
    #contact .w-30{
        width: 50%;
    }
    #patient-resources .w-40{
        width: 30%;
    }

    h2{
        font-size: 40px;
        letter-spacing: 0px;
    }
    h3{
        font-size: 32px;
        letter-spacing: 1px;
    }
    .btn-ss-primary{
        font-size: 16px;
    }
    .fs-ss-56{
        font-size: 42px;
    }
    
}

@media screen and (max-width: 768px) {
    
    .fs-ss-main{
        font-size: 46px;
    }
    .service-icon-size{
        width: 60%;
    }

    .footer-logo{
        height: 50px;
    }

    #website-responsive .fs-ss-20{
        font-size: 18px;
    }
    .slick-slide-content{
        top: 35%;
        width: 64%;
        left: 2vw;
    }
    .slick-slide-title{
        font-size: 42px;
        padding: 0 10px;
        top:20%;
        left: 5vw;
        width: 60%;
        line-height: 1.2em;
    }
    .slick-slide-title-desc{
        font-size: 2.7vw;
        font-weight: 500;
        padding: 0 10px 3px 10px;
    }
    .slick-slide-btn{
        top: 72%;
        left: 6vw;
    }
    .slick-slide-button{
        font-size: 18px;
    }

    #contact .w-30{
        width: 30%;
    }
    .image-width-90, .image-width-85{
        width: 100%;
    }
    
}

@media screen and (max-width: 631px) {

}

@media screen and (max-width: 576px) {
   
    .slick-slide-image{
        min-height: 210px;
        object-fit: cover;
    }
    
    .slick-slide-title{
        font-size: 24px;
        top: 20%;
        width: 65%;
        line-height: 1.15em;
    }

    .slick-slide-title-desc{
        top:45%;
        font-size: 4vw;
        font-weight: 500;
    }

    .slick-slide-btn{
        top: 68%;
        margin-left: 8px;
        width: 65%;
    }
    .slick-slide-button{
        font-size: 14px;
        padding: 6px 20px;  
    }


    .btn-ss-primary{
        font-size: 16.5px;
        min-width: 140px;        
    }

    .btn-ss-secondary{
        font-size: 18px;
        /* padding: 6px 20px;   */
    }

    .btn-width-250{
        min-width: 150px;
        padding-right: 40px;
        padding-left: 40px;
    }

    .main-sec{
        padding: 60px 15px 40px 15px;
    }

    .container-ss-90{
        padding-right: 0%;
        padding-left: 0%;
    }
    
    #footer .w-75{
        width: 70% !important;
    }
    #contact .w-30{
        width: 30%;
    }
    #about .w-25{
        width: 20% !important;
    }
    #patient-resources .w-25{
        width: 40% !important;
    }
    #cta .w-25{
        width: 15% !important;
    }
    #patient-resources .w-40{
        width: 45% !important;
    }

    .heading{
        font-size: 28px;
    }
    h1{
        text-transform: capitalize;
    }
    h2{
        font-size: 36px;
    }
    h3{
        font-size: 22px;
    }
    h4{
        font-size: 26px;
    }
    p{
        font-size: 15px;
    }
    .para{
        font-size: 14px;
    }
    .para1{
        font-size: 14px;
    }
    .sub-heading{
        font-size: 19px;
    }
    .sub-heading-v1{
        font-size: 17px;
    }
    
    .service-icon-size{
        width: 55%;
    }

    .image-width-90, .image-width-85, .image-width-80, .image-width-75{
        width: 100%;
    }
    
    .service-heading{
        font-size: 26px;
        line-height: 1.1em;
    }
    .service-heading1{
        font-size: 20px;
        font-weight: 800;
        line-height: 26px;
    }


    .fs-ss-56{
        font-size: 36px;
    }
    .fs-ss-46{
        font-size: 32px;
    }
    .fs-ss-40{
        font-size: 32px;
    }
    .fs-ss-36{
        font-size: 28px;
    }
    #contact .fs-ss-36{
        font-size: 32px !important;
    }
    #contact .main-sec .fs-ss-36{
        font-size: 28px !important;
    }
    .fs-ss-24{
        font-size: 22px;
    }
    #website-responsive .fs-ss-20{
        font-size: 18px;
    }
    .fs-ss-18{
        font-size: 16px;
    }
    #contact .fs-ss-18{
        font-size: 20px !important;
    }
    .fs-ss-16{
        font-size: 15px;
    }
    #copyright .fs-ss-16{
        font-size: 14px;
    }
    .fs-ss-15{
        font-size: 14px;
    }

    .text-left-desk{
        text-align: center;
    }
    .text-justify-desk{
        text-align: left;
    }



    .insurance-logo img{
        width: 140px;
    }

    
    .home-services-img{
        width:120px;
    }
    .home-mobile-pic{
        width: 50%;
    }

    /* #website-responsive .fs-zk-92{
        font-size: 45px;
    }

    #website-responsive .service-healthcare-bg  .fs-zk-92{
        font-size: 35px;
    } */

    #home-middle-sec .fs-ss-36{
        font-size: 16px;
    }
    #home-middle-sec .fs-ss-44{
        font-size: 18px;
    }
    #middle-sec2 .fs-ss-36{
        font-size: 26px;
    }
    #middle-sec2 .fs-ss-44{
        font-size: 28px;
    }


    #website-responsive .fs-zk-68{
        font-size: 32px;
    }

    
    #website-responsive .fs-zk-56, #website-responsive .fs-zk-58{
        font-size:28px;
    }
    #website-responsive .fs-zk-52{
        font-size:27px;
    }
    
    #website-responsive .fs-zk-48{
        font-size: 26px;
    }

    #website-responsive .fs-zk-40{
        font-size: 24px;
    }

    #website-responsive .fs-zk-35{
        font-size: 22px;
    }

    #website-responsive .fs-zk-30, #website-responsive .fs-zk-28{
        font-size: 20px;
    }

    #website-responsive .fs-zk-26, #website-responsive .fs-zk-24{
        font-size: 19px;
    }

    .navbar-brand .fs-zk-26{
        font-size: 19px;
    }

    #website-responsive .fs-zk-22{
        font-size: 18px;
    }
    #website-responsive .fs-zk-18, #website-responsive .fs-zk-17{
        font-size:16px;
    }
    .about-health-section .fs-zk-30{
        font-size: 23px !important;
    }

    .container-ss, .container-ss2{
        max-width: 96%;
        padding-right: 2% !important;
        padding-left: 2% !important;
        margin-right: auto;
        margin-left: auto;
    }
    
}



@media screen and (max-width: 480px) {
    .page-banner-title{
        top:47%;
    }
}


@media screen and (max-width: 400px) {
    .page-banner-title{
        top:47%;
    }

    table, .fs-ss-17{
        font-size: 15px !important;
    }
}

@media screen and (max-width: 320px) {
    .page-banner-title{
        top:35%;
    }
    
}

@media screen and (max-width: 280px) {
    
}


/* CSS */
/* .bg-transparent{
    background-color: rgba(0, 128, 0, 0) !important;
} */

.rounded-100{
    border-radius: 100px;
}

.w-icons{
    width: 46px;
}

.text-star{
    color: #fec42d;
}

.bg-ss-primary-light{
    background-color: #ff7991;
}

.bg-ss-primary{
    background-color: var(--primary-ss-color) !important;
}


.slick-prev::before{
    color: transparent !important;
}

.slick-next::before{
    color: transparent !important;
}

.slick-dots li button:before {
    font-size: 10px !important;
    color: var(--primary-ss-color) !important;
}

.w-90{
    width: 90% !important;
}

.rounded-top-testimonials{
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

/* .h-specific{
    height: 800px !important;
} */

.text-uppercase-desk{
    text-transform: uppercase;
}

.btn-close:hover{
    background-color: var(--primary-ss-color);
    opacity: 1;
    border: 1px solid var(--poppins-ss-font-family);

}

.btn-close{
    border: 1px solid var(--black-color);
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.navbar-toggler-icon{
    background-image: url(../images/menu.png);
    width: 25px !important;
}

.slider-para{
    min-height: 240px;
}